@import 'src/common/variables';

#tributes {

  .email-link {
    color: $color-accent1;
  }

  .primary-button, .secondary-button {
    font-family: $content-font;
    font-size: 1rem;
    width: fit-content;
    padding: 0.5rem;
    background-color: transparent;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
  }

  .primary-button:hover, .secondary-button:hover {
    outline: 2px solid $color-accent2;
  }

  .primary-button {
    background: $color-accent2;
    color: $color-white;
  }

  .primary-button.button-spacer {
    margin-right: 0.5rem;
  }

  .tribute-container {
    position: relative;
    padding: 1rem;
    margin: 2rem auto;
    box-shadow: 0 0 0.5rem 0.1rem rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.03);

    .tribute-date {
      font-style: italic;
    }

    .tribute-message {
      text-align: left;
      margin: 1rem 0 1.5rem 0;
      white-space: pre-wrap;
    }

    .tribute-fullName {
      text-align: right;
      font-family: $header-font;
      color: $color-accent1;
    }
  }

  .contribution-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 4rem;

    .required {
      color: red;
    }

    .row {
      display: flex;
      justify-content: space-evenly;
      gap: 10px;

      label {
        width: 100%;
      }

      input {
        font-family: $content-font;
        font-size: 1.2rem;
        line-height: 1.2rem;
        width: 65%;
        padding: 0.2rem;
        margin-left: 1rem;
        border: 1px solid #ccc;
        border-radius: 5px;
      }
    }

    .message-wrapper {
      display: flex;
      flex-direction: column;

      .message {
        font-family: $content-font;
        font-size: 1.2rem;
        line-height: 1.69rem;
        width: 100%;
        height: 18.58rem;
        min-height: 18.58rem;
        padding: 0.2rem;
        margin-top: 0.5rem;
        border: 1px solid #ccc;
        border-radius: 5px;
        resize: none;
      }

      .characters-remaining {
        text-align: right;
      }
    }
  }
}