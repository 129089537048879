@import 'src/common/variables';

.not-found-container {
  background-image: url('../images/not-found-background.svg');
  background-size: 100%;
  color: $color-white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;

  .not-found-left, .not-found-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    padding: 0 10rem;
  }

  .not-found-left {
    border-right: 1px solid $color-white;
    padding-left: 20rem;
    text-shadow: rgba(0,0,0,0.5) 1rem 1rem 1.5rem;

    h1 {
      font-size: 9rem;
    }

    .icon {
      font-size: 8rem;
      margin-left: 0.8rem;
      color: $color-accent2;
    }
  }

  .not-found-right {
    align-items: flex-start;
    padding-right: 20rem;

    h2 {
      font-size: 4rem;
      text-transform: uppercase;
      padding: 0;
      margin: 0;
      text-shadow: rgba(0,0,0,0.5) 1rem 1rem 1.5rem;
    }

    p {
      font-size: 2rem;
    }

    .go-back {
      font-size: 1.3rem;

      .link-icon {
        margin-right: 0.5rem;
        font-weight: 600;
        color: $color-accent2;
      }

      a {
        text-decoration: none;
        color: $color-accent2;
      }
    }
  }
}