@import 'src/common/variables';

.quote-block {
  display: flex;
  align-items: flex-start;
  padding: 0;
  margin: 0 auto;
  max-width: 600px;
}

.quote-icon {
  font-family: $quote-char-font;
  font-size: 4em;
  color: $color-accent2;
  transform: scaleY(-1);
  line-height: 0;
}

.quote-text {
  font-family: $quote-text-font;
  font-size: 1.5rem;
  color: $color-accent1;
  flex-grow: 1;
  margin: 0 0 0 1rem;
  text-align: justify;

  span {
    display: block;
    text-align: right;
    font-style: italic;
    font-size: 1.2rem;
  }
}
