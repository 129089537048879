@import 'src/common/variables';

.timeline-wrapper {
  .timeline-date {
    color: black;
  }

  .highlight {
    color: $color-accent2;
  }
}