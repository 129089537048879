@import url('https://fonts.googleapis.com/css2?family=IM+Fell+French+Canon:ital@0;1&family=Kaisei+HarunoUmi&display=swap&family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap&family=Allura&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IM+Fell+French+Canon:ital@0;1&family=Kaisei+HarunoUmi&display=swap');

$banner-font: 'Allura', cursive;
$header-font: 'Kaisei HarunoUmi', serif;
$content-font: 'Noto Sans', serif;
$quote-char-font: 'IM Fell French Canon', serif;
$quote-text-font: 'Kaisei HarunoUmi', serif;

$header-height: 25rem;
$main-height: 100vh;
$footer-height: 4rem;

$color-accent1: #2056bf;
$color-accent2: #fc906c;
$color-white: #ffffff;