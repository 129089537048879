@import 'src/common/variables';

.layout {
  header {
    height: $header-height;
    background-image: url('../images/banner1.jpg');
    background-size: cover;
    background-position: center;
    box-shadow: 0 0.875rem 1.5rem 0 rgba(0, 0, 0, 0.08);

    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;

    .banner-title {
      font-family: $banner-font;
      font-size: 6rem;
      color: $color-white;
      text-shadow: rgba(0,0,0,0.5) 1px 0 10px;
      width: 60%;
      opacity: 0.8;
    }
  }

  main {
    font-family: $content-font;
    font-weight: 200;
    min-height: calc($main-height - $header-height - $footer-height);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;

    .content-wrapper {
      width: 60%;

      .portrait-wrapper, .portrait-wrapper-mini {
        margin-top: -100px;
        padding-bottom: 2rem;
        display: grid;
        grid-template: auto / auto auto;
        grid-gap: 100px;
        justify-content: flex-start;
        position: sticky;
        top: 0;
        z-index: 300;
        transition: grid-gap 0.3s;

        .portrait {
          transform: translateY(0px);
          opacity: 1;
          transition: transform 0.3s, opacity 1.3s ease-in-out;
          grid-row: 1 / 2;
          grid-column: 1 / 2;
          width: 18.75rem;
          aspect-ratio: 1;
          box-shadow: 0 0.875rem 1.5rem 0 rgba(0, 0, 0, 0.08);
          border-radius: 10px;
          background: $color-white;
          border: 5px solid $color-white;
          z-index: 2;

          .portrait-container {
            overflow: hidden;
            width: 100%;
            height: 100%;
            border-radius: 10px;

            div.img {
              background-image: url('../images/portrait.jpg');
              background-size: cover;
              background-position: center;
              --position: 50%;
              scale: 1;
              width: 100%;
              height: 100%;
              object-fit: cover;
              border-radius: 2px;
              box-sizing: border-box;
            }
          }
        }

        .portrait-mini {
          border: none;
          border-radius: 0;
          width: 3rem;
        }

        .portrait-title {
          transform: translateY(0px);
          opacity: 1;
          transition: transform 0.3s, opacity 0.3s;
          grid-row: 1 / 2;
          grid-column: 2 / 3;
          justify-content: end;
          display: flex;
          flex-direction: column;
          line-height: 2;
          gap: 5px;
          font-size: 1.25rem;

          .name {
            font-family: $header-font;
            font-size: 3rem;
            padding: 0;
            margin: 0;
          }
        }
      }

      .portrait-wrapper-mini {
        margin-top: 0;
        grid-gap: 2rem;
        justify-content: center;
        background-color: rgba(255, 255, 255, 0.9);
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        padding: 1rem 0 2rem;

        .portrait {
          width: 5rem;
          box-shadow: none;
          border-radius: 0;
          border: none;

          .portrait-container {
            box-shadow: 0 0.875rem 1.5rem 0 rgba(0, 0, 0, 0.2);
            div.img {

            }
          }
        }

        .portrait-title {
          justify-content: center;
          line-height: 1;

          .name {
            font-size: 2.2rem;
          }
        }
      }

      menu.page-menu {
        position: sticky;
        top: 8rem;
        z-index: 1000;
        display: flex;
        justify-content: space-around;
        background-color: rgba(255,255,255,0.7);
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        padding: 0 0 1.5rem 0;
        margin-top: 0;

        ul {
          list-style-type: none;
          padding: 1.5rem;
          margin: 0;
          gap: 2rem;
          width: 100%;
          display: flex;
          justify-content: center;
          box-shadow: 0 0.875rem 1.5rem 0 rgba(0, 0, 0, 0.08);
          border-radius: 10px;
          border: 1px solid rgba(0, 0, 0, 0.03);

          li {
            margin: 0 0.75rem;
            font-size: 1.2rem;

            .menu-icon {
              color: $color-accent2;
              margin-right: 0.5rem;
              font-size: 1rem;
            }

            a {
              text-decoration: none;
              color: $color-accent1;
            }
          }
        }
      }

      article {
        padding: 2rem;
        font-size: 1.15rem;

        section {
          margin-bottom: 4rem;
          h2 {
            font-family: $header-font;
            font-size: 1.75rem;
            width: fit-content;
            color: $color-accent1;
          }
          h2::after {
            content: '';
            display: block;
            width: 4rem;
            height: 0.4rem;
            background-color: $color-accent2;
            margin-top: 0.4rem;
          }
        }
      }

    }
  }

  footer {
    height: $footer-height;
    background-color: $color-white;
    color: #ddd;
    font-style: italic;
    font-size: 1.2rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}