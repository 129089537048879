@import 'src/common/variables';

section.capitalized-first-letter {
  p:first-of-type::first-letter {
    font-family: $header-font;
    font-size: 3.5rem;
    float: left;
    line-height: 0.5;
    margin-top: 0.7rem;
    padding: 2px 8px 0 6px;
  }
}
